/* src/app/routes/users/user-profile/user-profile.component.scss */
.tittle {
  font-family: "Source Sans Pro", sans-serif;
  color: #4d4f5b;
  font-size: 30px;
}
.button-style {
  padding-left: 60px;
  padding-right: 60px;
  background-color: #ff7285;
  border-color: #ff7285;
  font-size: 18px;
}
.inline-block-display {
  display: inline-block;
  padding-right: 0px;
}
.body-position {
  padding-top: 60px;
}
.white-row {
  background-color: white;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 50px;
  padding-bottom: 70px;
}
.form-padding-top {
  padding-top: 70px;
}
.dd-padding-top {
  padding-top: 15px;
}
.padding-top {
  padding-top: 30px;
}
.padding-right {
  padding-right: 40px;
  padding-top: 30px;
}
.no-padding-left {
  padding-left: 0px;
}
.no-border {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.control {
  padding-left: 10px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-color: #e9e9f0;
  border-width: 2px;
  color: #a6a7ad;
  font-size: 16px;
}
.green-button {
  padding-left: 90px;
  padding-right: 90px;
  background-color: #69e4a6;
  border-color: #69e4a6;
  font-size: 18px;
}
.g-button-position {
  margin: 0 auto;
  padding-top: 60px;
}
.filter-dd > .dropdown-menu {
  width: 100%;
  background-color: white;
}
.filter-dd > .dropdown-toggle {
  background-color: white;
  padding-left: 15px;
  font-size: 16px;
  color: #a6a7ad;
  border-width: 2px;
}
.filter-dd-button {
  text-align: left;
}
.filter-dd-button::after {
  text-align: left;
}
.filter-dd > .dropdown-toggle::after {
  text-align: right;
  float: right;
  margin-top: -12px;
}
.filter-dd > .dropdown-toggle::after {
  text-align: right;
  float: right;
  margin-top: 8px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
/*# sourceMappingURL=user-profile.component.css.map */
