/* src/app/routes/users/user.component.scss */
.user-search > .search {
  padding: 30px 20px;
}
#sidebar-wrapper {
  z-index: 2;
  position: fixed;
  right: 0;
  top: 60px;
  width: 250px;
  height: 100%;
  margin-right: -250px;
  overflow-y: auto;
  background: #e5e6e7;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.side-opened.wrapper,
.wrapper.page {
  transition: all 0.2s ease-in-out;
}
.container.wrapper:not(.side-opened) {
  transition: all 0.5s ease-in-out;
}
.wrapper.side-opened {
  margin-left: 25px;
  transition: all 0.2s ease-in-out;
}
.side-opened #sidebar-wrapper {
  right: 250px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*# sourceMappingURL=user.component.css.map */
