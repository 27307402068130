<div class="wrapper page container">
  <div class="row page-tit">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'profile.title' | translate}}</h3>
      <button type="button" (click)="onSubmit()" class="btn blue-bg mg-12 float-right header-button">{{'profile.save_mod'
        | translate}}</button>
    </div>
  </div>
  <div class="row content-center">
    <div class="col-md-12 col-xs-12">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="card card-p  border-0">
            <div class="row col-md-12">
              <img *ngIf="logoPath" src="{{logoPath}}" class="img-responsive user-image-style">
              <div *ngIf="!logoPath && myData && myData.name && myData.surname && profile!='RESELLER'" class="capitals-profile">
                {{myData.name[0].charAt(0) + myData.surname[0].charAt(0)}}
              </div>
              <div *ngIf="!logoPath && myData && myData.name && profile=='RESELLER'" class="capitals-profile">
                {{myData.name[0].charAt(0)}}
              </div>
              <span class="card-text1" *ngIf="myData">{{ myData.name }} {{ myData.surname }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 form-padding">
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="name" id="name">
                    <label class="label custom-label" for="name"> {{'general.name' | translate}}</label>
                  </div>
                  <div class="has-float-label">
                      <input class="custom-float-label-input" type="text" placeholder=" " formControlName="username" id="username">
                      <label class="label custom-label" for="username"> {{'general.username' | translate}}
                      </label>
                  </div>
                  <div class="has-float-label">
                      <input class="custom-float-label-input " type="text" placeholder=" " formControlName="telephone" id="telephone"
                        required>
                      <label class="label custom-label" for="telephone"> {{'general.phone' | translate}} </label>
                  </div>  
                </div>  
                <div class="col-md-6 form-padding">
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="surname" id="surname">
                    <label class="label custom-label" for="surname"> {{'general.surname' | translate}} </label>
                  </div>
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="email" id="email"
                      required>
                    <label class="label custom-label" for="email"> {{'profile.email' | translate}} </label>
                  </div>               
                  <div class="has-float-label input-padding-bottom">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="profile" id="profile">
                    <label class="label custom-label" for="profile"> {{'profile.title' | translate}} </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
