<div class="wrapper page container">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'user.manage' | translate}}</h3>
      <button *ngIf="profile=='SUPERADMIN' || profile=='RESELLER' || (merchantInfo && merchantInfo.state != 'INACTIVE')" type="button" [routerLink]="['./new/user']" class="btn blue-bg mg-12 float-right header-button">
        {{'nav.new-user' | translate}}</button>
    </div>
  </div>
  <div class="card">
    <div class="w100 app-search">
      <div class="w800">
        <!-- <app-advance-search [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
          [selectOptionsPlaceholder]="'profile.selectUsers'" [selectOptions]="selectOptions" [checkboxes]="checkboxes"
          (searchEvent)="searchEvent($event)" (deletedChip)="chipDeleted()" (sortEvent)="sortEvent($event)">
        </app-advance-search> -->

        <!--<app-filter-search [page]="eFilterPageType.Utenti" [sortOptionsPlaceholder]="'profile.sort'"
          [selectOptionsPlaceholder]="'profile.selectUsers'" [selectOptions]="selectOptions"
          (searchEvent)="searchEvent($event)" (sortEvent)="sortEvent($event)">
        </app-filter-search>-->

        <!--<div class="m-4 p-4 bg-primary rounded">
          <i class="fa fa-info-circle fa-2x"></i> info
        </div>-->

        <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
          <form [formGroup]="searchForm">
            <div class="row">
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.name" [label]="'general.name'" [placeholder]="'general.name'"></sp-input-text>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.surname" [label]="'general.surname'" [placeholder]="'general.surname'"></sp-input-text>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.email" [label]="'general.email'" [placeholder]="'general.email'"></sp-input-text>
              <sp-input-user-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.profile" [multiple]="true" [label]="'general.type'" [placeholder]="'advance-search.select_type'"></sp-input-user-select>
              <sp-input-user-status-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.status" [multiple]="true" [label]="'general.state'" [placeholder]="'advance-search.select_state'"></sp-input-user-status-select>
              <sp-input-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idMerchant" [label]="'general.merchant'" [placeholder]="'advance-search.select_merchant'"
                *ngIf="(profile=='SUPERADMIN' || profile=='RESELLER') && !userSelected"></sp-input-merchant-select>
            </div>
          </form>
        </sp-filter-section>

      </div>
    </div>
    <div class="table-margin">
      <table-expandable></table-expandable>
    </div>
  </div>
    
  <div id="sidebar-wrapper" *ngIf="profile=='SUPERADMIN' || profile=='RESELLER'">
    <app-sidebar-users [users]="users" (selectedUser)="selectedUser($event)"></app-sidebar-users>
  </div>

</div>