import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { TableData } from './ng2-table-data';
import { AppService } from '../../core/services/base.service';
import { LoggingService } from '../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import swal from 'sweetalert2';

@Component({
    selector: 'app-user',
    templateUrl: './user.manage.component.html',
    styleUrls: ['./user.manage.component.scss']
})
export class UserManageComponent implements OnInit {

    public idElement;
    public kindManage;
    public kindTitle;
    public errorMessage;
    public rows: Array<any> = [];
    public columns: Array<any> =  [];
    public element: any;
    public elementRoles: any;
    public elementRequest: any;
    public pathGetElement;
    public pathManageUser;
    public pathRoles;

    @ViewChild("formManageUser") userManageForm: NgForm;
    constructor(public http: HttpClient, public appService: AppService, private logService: LoggingService, private activeRoute: ActivatedRoute, private router: Router, private translate: TranslateService) {
      const sub = this.activeRoute.params.subscribe(params => {
        this.idElement = params['id'];
      });

      this.pathGetElement = '/admin/user/' + this.idElement;
      this.pathManageUser = '/admin/user';
      this.pathRoles = '/admin/role/roles';

      this.loadRoles();
      if (activeRoute.snapshot.toString().indexOf("new") !== -1) {
        this.kindTitle = "New";
        this.kindManage = 1;
      } else {
        this.kindTitle = "Modify";
        this.kindManage = 0;
        this.loadElement();
      }
    }

    public ngOnInit(): void {
    }

    public loadElement() {
      this.appService.getElement(this.pathGetElement)
      .subscribe((response) => {
        this.element = response;
        this.logService.log("User ", this.element, 300);
        },
        (error) => {
          this.logService.log("Error", error, 200);
        }
      );
    }

    public loadRoles() {
      this.appService.getElement(this.pathRoles)
      .subscribe((response) => {
        this.elementRoles = response.results;
        this.logService.log("Roles ", this.element, 300);
        },
        (error) => {
          this.logService.log("Error", error, 200);
        }
      );
    }

    onSubmit(): void {
      swal.showLoading();
      this.elementRequest = this.userManageForm.value;
      if (this.kindManage) {
        this.appService
        .newElement(this.pathManageUser, this.elementRequest)
        .subscribe(
          response => {
            if (response.outcome.success === true) {
              this.appService
              .invite(response.data.idUser)
              .subscribe(
                responseInvite => {
                  if (responseInvite.outcome.success === true) {
                    swal.fire("User created and invite sent!", "", "success");
                    this.router.navigate(["/user"]);
                  } else {
                    this.errorMessage = responseInvite.outcome.code + responseInvite.outcome.description;
                    swal.fire("Attention", this.errorMessage, "warning");
                  }
                },
                error => {
                  this.logService.log("Error", error, 200);
                  swal.fire("User created but invite not send!", "", "success");
                  this.router.navigate(["/user"]);
                }
              );
            } else {
              this.errorMessage = response.outcome.code + response.outcome.description;
              swal.fire("Attention", this.errorMessage, "warning");
            }
          },
          error => {
            this.logService.log("Error", error, 200);
            this.errorMessage = error.outcome.code + error.outcome.description;
            swal.fire("Attention", this.errorMessage, "warning");
          }
        );
      }
      if (this.kindManage === 0 ) {

        this.appService.editElement(this.pathManageUser + "/" + this.idElement, this.elementRequest).subscribe(
          response => {
            if (response.outcome && response.outcome.success === true) {
              swal.fire("User modified!", "", "success");
              this.router.navigate(["/user"]);
            } else {
              this.errorMessage =
                response.outcome.code + response.outcome.description;
              swal.fire("Attention", this.errorMessage, "warning");
            }
          },
          error => {
            this.logService.log("Error", error, 200);
            this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
            swal.fire(
              "Error",
              "Spiacente, si è verificato un errore tecnico.",
              "error"
            );
          }
        );
      }
    }

}
