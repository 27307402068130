<div class="content-heading ">
    <div>{{'users.title' | translate}}
        <small>{{kindTitle}}</small></div>
</div>
<div class="row justify-content-md-center">
  <div class="col-sm-12 col-md-6">
      <form #formManageUser="ngForm" role="form"  (ngSubmit)="onSubmit()" id="floating-label">
      <div class="card card-default">
          <div class="card-header">{{kindTitle}} User</div>
          <div class="card-body row ">
            <div class="form-group col-md-6" [ngClass]="{focused: kindManage == 0}">
                <label for="name" class="control-label" >Name</label>
                <input type="text" id="name" name="name" class="form-control" ngModel [ngModel]="element?.name"  #name="ngModel" required>
            </div>

            <div class="form-group col-md-6" [ngClass]="{focused: kindManage == 0}">
                <label for="surname" class="control-label" [class.focused]="kindManage == 0"> Surname</label>
                <input type="text" id="surname" class="form-control" ngModel [ngModel]="element?.surname" name="surname" surname #surname="ngModel" required>
            </div>

            <div *ngIf="kindManage == 0" class="form-group col-md-6" [ngClass]="{focused: kindManage == 0}">
                <label for="phone" class="control-label" [class.focused]="kindManage == 0"> Username</label>
                <input type="text" id="username" [disabled]="kindManage == 0" class="form-control" ngModel [ngModel]="element?.username" name="username" username #username="ngModel" required>
            </div>

            <div class="form-group col-md-6" [ngClass]="{focused: kindManage == 0}">
                <label for="phone" class="control-label" [class.focused]="kindManage == 0"> Email</label>
                <input type="email" id="email" [disabled]="kindManage == 0" class="form-control" ngModel [ngModel]="element?.email" name="email" email #email="ngModel" required>
            </div>

            <div  class="form-group col-md-6" [ngClass]="{focused: kindManage == 0}">
              <label for="roleUser" class="control-label " [class.focused]="kindManage == 0">Ruolo </label>
              <select type="text" id="codeRole" class="form-control"
                  ngModel [ngModel]="element?.codeRole" name="codeRole" codeRole #codeRole="ngModel" required>
                  <ng-container *ngFor="let role of elementRoles" >
                      <option [ngValue]="role.codeRole">{{role.codeRole}}</option>
                  </ng-container>
              </select>
            </div>
            <div class="form-group form-group col-md-6" [ngClass]="{focused: kindManage == 0}">
              <label for="status" class="control-label " [class.focused]="kindManage == 0">Stato</label>
                <select type="text" [ngModel]="element?.enabled" id="enabled" class="form-control" ngModel name="enabled" enabled #enabled="ngModel" required>
                    <option [ngValue]="true"> Active </option>
                    <option [ngValue]="false"> Inactive </option>
                </select>
            </div>
          </div>
          <div class="card-footer text-center">
            <button class="btn btn-info" type="submit" [disabled]="!formManageUser.valid">{{kindTitle}}</button>
          </div>
      </div>
      </form>
  </div>
</div>
