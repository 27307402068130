/* src/app/routes/users/change-password/change-password.component.scss */
.logo-padded {
  max-width: 256px;
  min-height: 96px;
  object-fit: none;
}
.secondary-text-color {
  color: #a5a6ac;
  margin-bottom: 50px;
  font-size: 20px;
}
.control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 17px;
  padding-left: 0px;
}
::placeholder {
  color: #4e505d;
  opacity: 1;
}
.footer-padding-top {
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .forgot-form {
    bottom: 0;
    height: 50%;
    margin: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 450px;
  }
  .forgot-form {
    height: 80%;
    position: absolute;
  }
}
@media (max-width: 768px) {
  .forgot-form {
    width: 100%;
  }
}
.bg {
  height: 100%;
  background-color: #fff;
}
/*# sourceMappingURL=change-password.component.css.map */
