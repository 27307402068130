/* src/app/routes/users/activate-user/activate-user.component.scss */
.logo-padded {
  max-width: 256px;
  min-height: 96px;
  object-fit: none;
}
.secondary-text-color {
  color: #a5a6ac;
  margin-bottom: 50px;
  font-size: 20px;
}
.control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 17px;
  padding-left: 0px;
}
::placeholder {
  color: #4e505d;
  opacity: 1;
}
.card {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
.footer-padding-top {
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .forgot-form {
    position: fixed;
    top: 17%;
  }
  .width-450 {
    width: 450px;
  }
}
.forgot-form {
  width: 100%;
}
.bg {
  height: 100%;
  background-color: white;
}
/*# sourceMappingURL=activate-user.component.css.map */
