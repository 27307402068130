<div class="bg">

  <div class="block-center forgot-form">
    <div class="card-flat text-center">
      <div class="text-center bg-secondary">
        <a href="#">
          <img class="block-center rounded logo-padded" src="assets/img/service-pay-logo.png" alt="Image" />
        </a>
      </div>
      <div class="card-body width-450">
        <form (ngSubmit)="onSubmit()" class="" [formGroup]="formRecover">
          <p class="text-center secondary-text-color">{{'password.change_pwd_title' | translate}}</p>
          <div class="form-group">
            <div class="has-float-label">
              <input  class="custom-float-label-input" id="password" type="password" placeholder=" "
                formControlName="password" />
              <label class="label custom-label" for="password"> {{'general.password' | translate}}</label>
            </div>
            <div class="text-danger" *ngIf="formRecover.controls.password.invalid && formIsSubmited">{{"password.must_match_regex" | translate}}</div>
            <div class="has-float-label">
              <input validatePass [(ngModel)]="account.password" class="custom-float-label-input" type="password" placeholder=" " formControlName="newPassword"
                id="newPassword" popover="{{'password.must_match_regex' | translate}}" triggers="click:blur" (onShown)="autoClose()" #pop="bs-popover">
              <label class="label custom-label" for="newPassword"> {{'password.new_password' | translate}}</label>
            </div>
            <div class="text-danger" *ngIf="formRecover.controls.newPassword.invalid && formIsSubmited">{{"password.must_match_regex" | translate}}</div>
            <div class="has-float-label">
              <input validatePass class="custom-float-label-input" type="password" placeholder=" " formControlName="repeatNewPassword"
                id="repeatNewPassword">
              <label class="label custom-label" for="repeatNewPassword"> {{'password.repeat_new_pwd' | translate}}</label>
            </div>
            <div class="text-danger" *ngIf="formRecover.controls.repeatNewPassword.invalid && formIsSubmited">{{"password.must_match_regex" | translate}}</div>
            <div class="text-danger" *ngIf="passwordMisMatch">{{"password.password_mismatch" | translate}}</div>
          </div>

          <div class="d-flex mt-70">
            <button  class="btn btn-purple-custom" type="submit">
              {{'password.send' | translate}}
            </button>
            <button (click)="backClicked()" class="btn btn-purple-custom" type="button">
              {{'password.exit' | translate}}
            </button>
          </div>
          

        </form>
      </div>
    </div>
  </div>
</div>
