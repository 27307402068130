<div class="wrapper bg">

    <div class="block-center forgot-form">
        <div class="card card-flat text-center">
            <div class="card-header text-center bg-secondary">
                <a href="#">
                    <img class="block-center rounded logo-padded" src="assets/img/service-pay-logo.png" alt="Image" />
                </a>
            </div>
            <div class="card-body width-450">
                <form (ngSubmit)="onSubmit()" class="" [formGroup]="formRecover">
                    <p class="text-center secondary-text-color">{{'password.change_pwd_title' | translate}}</p>
                    <div class="form-group">
                        <div class="has-float-label">
                            <input class="custom-float-label-input" id="password" type="text" placeholder=" " formControlName="password" />
                            <label class="label custom-label" for="password"> {{'general.password' | translate}}</label>
                        </div>
                        <div class="has-float-label">
                            <input [(ngModel)]="account.password" class="custom-float-label-input" type="text" placeholder=" " formControlName="newPassword" id="newPassword">
                            <label class="label custom-label" for="newPassword"> {{'password.new_password' | translate}}</label>
                        </div>
                        <div class="has-float-label">
                            <input class="custom-float-label-input" type="text" placeholder=" " formControlName="repeatNewPassword" id="repeatNewPassword">
                            <label class="label custom-label" for="repeatNewPassword"> {{'password.repeat_new_pwd' | translate}}</label>
                        </div>
                        <ngx-password-strength-bar
                            [passwordToCheck]="account.password"
                            [barLabel]="barLabel"
                            [barColors]="myColors"
                            [baseColor]="baseColor"
                            [strengthLabels]="strengthLabels"
                            (onStrengthChanged)="strengthChanged($event)">
                        </ngx-password-strength-bar>
                        <div class="text-danger" *ngIf="errorMessage">{{errorMessage}}</div>
                    </div>
                    <button [disabled]="!formRecover.valid" class="btn btn-block btn-purple-custom mt-70" type="submit">{{'password.send' | translate}}</button>
                    <button (click)="backClicked()" class="btn btn-block btn-purple-custom" type="button" >{{'password.exit' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</div>
