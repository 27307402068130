import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';
import { Location } from '@angular/common';
import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  public account = {
    password: <string>null,
  };
  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public barColors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  public baseColor = '#DDD';
  public strengthLabels = ['(Useless)', '(Weak)', '(Normal)', '(Strong)', '(Great!)'];
  strength: any;
  formErrors: any = {
    "password": {}
  };
  passwordMisMatch: any = false;
  passwordMatch: any = false;
  formIsSubmited: any = false
  formRecover = this.formBuilder.group({
    password: ['', Validators.required],
    newPassword: ['', Validators.required],
    repeatNewPassword: ['', Validators.required]
  });

  msgTranslation;
  errorTranslation;
  errorMessage;

  @ViewChild('pop') pop: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    public settings: SettingsService,
    private logger: LoggingService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private userService:UserService) {
      this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
  }

  ngOnInit() {
    this.formRecover.valueChanges.subscribe(() => {
      this.formIsSubmited = false;
    })
  }

  onSubmit(): void {
    this.formIsSubmited = true;
    if (this.formRecover.status == 'VALID') {
      this.passwordMatch = this.checkPassword() 
      if(this.passwordMatch){
        swal.close()
        swal.fire(this.translate.instant('password.password_match'), this.errorMessage, "error"); 
        return 
      }
      this.passwordMisMatch = this.checkNewPassword()
      if (!this.passwordMisMatch) {
        swal.fire({
          title: this.msgTranslation.loading,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => swal.showLoading()
        });
        let changeAtempt = { "oldPassword": this.formRecover.get("password").value, "newPassword": this.formRecover.get("newPassword").value }
        this.userService.changePwd(changeAtempt).subscribe((res: any) => {
          if (res.outcome.success === true) {
            swal.close()
            swal.fire(this.translate.instant('password.success'), "", "success").then((result) => {
            this.router.navigate(['/home']);
            }) 
          } 
          else {
            swal.fire(this.translate.instant('password.old_pass'), this.errorMessage, "error");
          }
        },
        (error) => {
          swal.close()
          this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
          swal.fire("Error", this.errorMessage, "error");
        }) 
      }
    }
  }

  strengthChanged(strength: number) {
    this.strength = strength;
  }
  
  backClicked() {
    this.location.back();
  }

  checkPassword() {
    let newPass = this.formRecover.get('newPassword').value
    let oldPass = this.formRecover.get('password').value
    return newPass == oldPass
  }

  checkNewPassword() {
    let newPass = this.formRecover.get('newPassword').value
    let repeatedPass = this.formRecover.get('repeatNewPassword').value
    return newPass != repeatedPass
  }

  autoClose() {
    setTimeout(() => {
      this.pop.hide()
    }, 5000);
  }

}



