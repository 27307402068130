<ngx-datatable #dataTable class='outer-table material expandable data-table' [columnMode]="'force'" [headerHeight]="0"
  [footerHeight]="70" [rowHeight]="100" [scrollbarH]="true" [scrollbarV]="false" [rows]='rows' *ngIf="rows.length > 0" [limit]="tableRecordNumbers"
  (page)="onPage($event)" (select)='onSelect($event)' [sorts]="[{prop: sortProp, dir: 'asc'}]" [messages]="my_messages">
  
  <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
      <div class="white">
        <div class="col-md-12 inner-panel padding-inner-table">
          <div class="row" *ngIf="row.userDetails">
            <div class="col-md-4">
              <ul class="list-group list_like_table">
                <!-- <li class="list-item list-group-item">{{'profile.vat_number' | translate}} <b> {{row.userDetails.vatNumber}}</b> </li>
                <li class="list-item list-group-item">{{'profile.fiscal_code' | translate}} <b> {{row.userDetails.fiscalCode}}</b> </li>
                <li class="list-item list-group-item">{{'profile.email' | translate}} <b> {{row.userDetails.email}}</b></li> -->
                <li class="list-item list-group-item">{{'general.name' | translate}} <b> {{row.userDetails.name}}</b> </li>
                <li class="list-item list-group-item">{{'general.surname' | translate}} <b> {{row.userDetails.surname}}</b> </li>
                <li class="list-item list-group-item" *ngIf="row.userTableList.profile != 'RESELLER'">{{'general.merchant' | translate}} <b> {{row.merchantCompanyName}}</b> </li>
                <li class="list-item list-group-item" *ngIf="row.userTableList.profile == 'RESELLER'">{{'general.reseller' | translate}} <b> {{row.userDetails.resellerDescription}}</b> </li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul class="list-group list_like_table">
                <!-- <li class="list-item list-group-item">{{'general.celular' | translate}} <b> {{row.userDetails.telephone}}</b></li>
                <li class="list-item list-group-item">{{'general.phone' | translate}} <b> {{row.userDetails.phoneNumber}}</b></li>
                <li class="list-item list-group-item">{{'manage-user.days' | translate}} <b> {{row.userDetails.paymentDay}}</b> </li> -->
                <li class="list-item list-group-item">{{'general.email' | translate}} <b> {{row.userDetails.email}}</b> </li>
                <li class="list-item list-group-item">{{'general.phone' | translate}} <b> {{row.userDetails.telephone ? row.userDetails.telephone : '-'}}</b> </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="table-wrapper padding-inner-table">
          <h4 *ngIf="row.userTableList.length > 0">{{'user.related_users' | translate}}: </h4>
          <ngx-datatable #table class='material  expandable data-table' [columnMode]="'force'" [headerHeight]="0"
            [footerHeight]="50" [rowHeight]="100" [scrollbarH]="true" [scrollbarV]="false" [rows]='row.userTableList' *ngIf="row.userTableList.length > 0"
            [limit]="tableRecordNumbers" (page)="onPage($event)" (select)='onSelect($event)' [messages]="my_messages">
            <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="white">
                  <div class="col-md-12 inner-panel padding-inner-table">
                    <div class="row" *ngIf="row.userDetails">
                      <div class="col-md-4">
                        <ul class="list-group list_like_table">
                          <li class="list-item list-group-item">{{'general.name' | translate}} <b>
                              {{row.userDetails.name}}</b> </li>
                          <li class="list-item list-group-item">{{'general.surname' | translate}} <b>
                              {{row.userDetails.surname}}</b> </li>
                          <li class="list-item list-group-item">{{'profile.email' | translate}} <b> {{row.userDetails.email}}</b></li>
                        </ul>
                      </div>
                      <div class="col-md-4">
                        <ul class="list-group list_like_table">
                          <li class="list-item list-group-item">{{'general.celular' | translate}} <b> {{row.userDetails.telephone ? row.userDetails.telephone : '-'}}</b></li>
                          <li class="list-item list-group-item">{{'general.phone' | translate}} <b> {{row.userDetails.phoneNumber ? row.userDetails.phoneNumber : '-'}}</b></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>
            <ngx-datatable-column name="Name" [width]="250">
              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                <div class="row">
                  <div class="col-2" *ngIf="row.profile != 'RESELLER'">
                    <p class="capitals start" *ngIf="row.name && row.surname">
                      {{row.name[0].charAt(0)}}{{row.surname[0].charAt(0)}}</p>
                  </div>
                  <div class="col-2" *ngIf="row.profile == 'RESELLER'">
                    <p class="capitals start" *ngIf="row.name">
                      {{row.name[0].charAt(0)}}</p>
                  </div>
                  <div class="col-10">
                    <h4>{{row.name + " " + row.surname}}</h4>
                    <div class="secondary-text-color">{{row.username}}</div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Profile" [width]="150">
              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                <div class="a-center">
                  <div class="user-info dark-bg a-center">{{ 'user.profile_role.'+row.profile | translate }}</div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" [width]="150">
              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center justify-content-center">
                  <!-- <div class="status-div status-div-table {{row.state}}">{{row.state}}</div> -->
                  <ng-template [ngIf]="row.state === 'Attivo' ">
                    <div class="status-div status-div-table {{row.state}}">{{ 'users.attivo' | translate }}</div>
                  </ng-template>
                  <ng-template [ngIf]="row.state === 'Bloccato' ">
                    <div class="status-div status-div-table {{row.state}}">{{ 'users.bloccato' | translate }}</div>
                  </ng-template>
                  <ng-template [ngIf]="row.state === 'Nuovo' ">
                    <div class="status-div status-div-table {{row.state}}">{{'users.nuovo' | translate }}</div>
                  </ng-template>
                  <!--<div class="status-pencil">
                    <app-status-select [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)" (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-select>
                  </div>-->
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false"
              [canAutoResize]="false">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <div class="f-right">
                  <button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandRowUser(row,expanded)">
                    <span *ngIf="!expanded">{{'sale.details_button' | translate}}</span>
                    <span *ngIf="expanded">{{'general.close' | translate}}</span>
                  </button>
                  <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button" aria-expanded="false" style=" text-decoration: none;">
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu detail-dropdrown" role="menu">
                    <li class="nav-item">
                      <a class="nav-link edit action_enabled" (click)="toggleUserActivation(row)">
                        <ng-container *ngIf="row.state != 'Attivo'">{{'user.activate-user' | translate}}</ng-container>
                        <ng-container *ngIf="row.state == 'Attivo'">{{'user.deactivate-user' | translate}}</ng-container>
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-row-detail>

  <ngx-datatable-column name="Fullname" prop="name" [width]="250">
    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
      <div class="row">
        <div class="col-2" *ngIf="row.profile != 'RESELLER'">
          <p class="capitals start" *ngIf="row.name && row.surname">
            {{row.name[0].charAt(0)}}{{row.surname[0].charAt(0)}}</p>
        </div>
        <div class="col-2" *ngIf="row.profile == 'RESELLER'">
          <p class="capitals start" *ngIf="row.name">
            {{row.name[0].charAt(0)}}</p>
        </div>
        <div class="col-10">
          <h4 class="text-wrap">{{row.name + " " + row.surname}}</h4>
          <div class="secondary-text-color">{{row.username}}</div>
          <div *ngIf="row.profile != 'RESELLER'">{{row.merchantCompanyName}}</div>
          <div *ngIf="row.profile == 'RESELLER'">{{row.resellerDescription}}</div> 
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Profile" [width]="150">
    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
      <div class="status-div dark-bg">{{'user.profile_role.'+row.profile | translate}}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" [width]="150">
    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
      <div class="d-flex align-items-center justify-content-center">
        <!-- <div class="status-div status-div-table  {{row.state}}">{{row.state}}</div> -->
        <ng-template [ngIf]="row.state === 'Attivo' ">
          <div class="status-div status-div-table {{row.state}}">{{ 'users.attivo' | translate }}</div>
        </ng-template>
        <ng-template [ngIf]="row.state === 'Bloccato' ">
          <div class="status-div status-div-table {{row.state}}">{{ 'users.bloccato' | translate }}</div>
        </ng-template>
        <ng-template [ngIf]="row.state === 'Nuovo' ">
          <div class="status-div status-div-table {{row.state}}">{{'users.nuovo' | translate }}</div>
        </ng-template>
      </div>
      <!--<div class="status-pencil">
        <app-status-select [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)" (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-select>
      </div>-->
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
      <div class="f-right">
        <button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandFirstRow(row,expanded)">
          <span *ngIf="!expanded">{{'sale.details_button' | translate}}</span>
          <span *ngIf="expanded">{{'general.close' | translate}}</span>
        </button>
        <ng-container dropdown>
          <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" dropdownToggle role="button"
            aria-expanded="false" style=" text-decoration: none;">
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu detail-dropdrown" *dropdownMenu role="menu">
            <li class="nav-item">
              <a class="nav-link edit action_enabled" *ngIf="canEdit(row)" [routerLink]="['./edit/user/'+ row.idUser]">{{'sale.modificate-user' | translate}}</a>
              <a class="nav-link edit action_enabled" *ngIf="canEdit(row)" (click)="toggleUserActivation(row)">
                <ng-container *ngIf="row.state != 'Attivo'">{{'user.activate-user' | translate}}</ng-container>
                <ng-container *ngIf="row.state == 'Attivo'">{{'user.deactivate-user' | translate}}</ng-container>
              </a>
              <a class="nav-link edit action_disabled" *ngIf="!hasActionToShow(row)">{{'general.label_empty_options' | translate}}</a>
            </li>
          </ul>
        </ng-container>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<div class="" *ngIf="rows.length == 0">
  <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
</div>